import { capitalize } from 'lodash'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ITaskFormData } from 'src/service/OrgTypes'
import { massUpdateTasks } from 'src/service/TaskService'
import useProjectId from '../../hooks/useProjectId'
import CreateTaskForm from '../CreateTaskForm'

interface TaskMultiEditProps {
  taskIds: number[]
  onClose: () => void
  onSave: () => void
  show: boolean
  defaultTask?: Partial<ITaskFormData>
}

const TaskMultiEdit: FC<TaskMultiEditProps> = ({
  taskIds,
  onClose,
  onSave,
  show,
  defaultTask,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()

  const massUpdate = async (data: Partial<ITaskFormData>) => {
    await massUpdateTasks(projectId, taskIds, data)
    onSave()
    onClose()
  }

  return show ? (
    <CreateTaskForm
      title={capitalize(t('edit_multiple_tasks'))}
      closeModal={onClose}
      customCreateTask={massUpdate}
      multiEdit
      defaultTask={defaultTask}
    />
  ) : null
}

export default TaskMultiEdit
