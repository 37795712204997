import { capitalize } from 'lodash'
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UploadFile from 'src/components/document/UploadFile'
import UploadFileList from 'src/components/document/UploadFileList'
import useProjectId from 'src/components/hooks/useProjectId'
import Selector from 'src/components/selectors/Selector'
import {
  initializeMetaValues,
  setExistingValues,
} from 'src/components/system/SystemUtil'
import TaskRelations from 'src/components/task/TaskRelations'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import { useProjectDisciplines } from 'src/query/disciplines'
import { useProjectTaskTypes } from 'src/query/tasks/taskType'
import { useDisciplineUsers, useProjectUsers } from 'src/query/users'
import { getUploadDocURl } from 'src/service/FileUploadService'
import { IDocument, ITaskFormData, ITaskType } from 'src/service/OrgTypes'
import { actionStatus } from 'src/service/SystemValues'
import { createTask } from 'src/service/TaskService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { renderDayContents } from 'src/utility/Utility'
import TaskOptionalFields from './mass-edit/TaskOptionalFields'

type ITaskFormProps = {
  closeModal: () => void
  defaultTask?: Partial<ITaskFormData>
  lastAllowedDeadline?: moment.Moment
  deadlineErrorMessage?: string
  customCreateTask?: (task: Partial<ITaskFormData>) => void | Promise<void>
  title?: string
  multiEdit?: boolean
}

type ITaskFormInput = Partial<ITaskFormData>

const getMetaData = (taskTypeId: number, taskTypes: ITaskType[]) => {
  const metaFields = initializeMetaValues(
    taskTypes.find((t) => t.id === taskTypeId)?.optional_fields ?? [],
    'Task',
  )
  return setExistingValues([], metaFields)
}

const CreateTaskForm: React.FC<ITaskFormProps> = ({
  closeModal,
  defaultTask,
  lastAllowedDeadline,
  deadlineErrorMessage,
  customCreateTask,
  title,
  multiEdit = false,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const [loading, setLoading] = useState<boolean>(false)
  const [deadlineDatePickerFocused, setDeadlineDatePickerFocused] =
    useState<boolean>(false)
  const [taskFromParent, setTaskFromParent] = useState<Partial<ITaskFormData>>(
    defaultTask ?? {},
  )

  const { control, handleSubmit, watch, setValue } = useForm()

  const discipline_id: number | undefined = watch('discipline_id')
  const responsible_id: number | undefined = watch('responsible_id')
  const uploadedDocuments: IDocument[] | undefined = watch('documents')
  const taskTypeId = watch('task_type_id')

  const onSubmit =
    (createMultiple: boolean) => async (data: ITaskFormInput) => {
      setLoading(true)
      const newTask = {
        ...taskFromParent,
        ...data,
        status: 'open',
        project_id: projectId,
        taskType: taskFromParent?.taskType ?? 'Aksjon',
        parent_id: taskFromParent?.parent_id ?? projectId,
        parent_type: taskFromParent?.parent_type ?? 'Project',
      }
      customCreateTask
        ? await customCreateTask(multiEdit ? data : newTask)
        : await createTask(newTask)
      setLoading(false)
      if (!createMultiple) closeModal()
      if (createMultiple) setValue('title', '')
    }

  const {
    data: disciplines = [],
    isLoading: disciplinesLoading,
    refetch: reFetchDisciplines,
  } = useProjectDisciplines()

  const {
    data: disciplineUsers = [],
    isLoading: disciplineUsersLoading,
    refetch: reFetchDisciplineUsers,
  } = useDisciplineUsers(discipline_id ?? 0, {
    enabled: !!discipline_id,
  })

  const {
    data: projectUsers = [],
    isLoading: projectUsersLoading,
    refetch: reFetchProjectUsers,
  } = useProjectUsers({
    enabled: !!responsible_id,
  })

  const {
    data: taskTypes = [],
    isLoading: taskTypesLoading,
    refetch: refetchTaskTypes,
  } = useProjectTaskTypes()
  const taskType = taskTypes.find((taskType) => taskType.id === taskTypeId)

  const userDisciplines = useMemo(() => {
    const user = projectUsers.find((user) => user.id === responsible_id)
    return user && user.disciplines
      ? user.disciplines.filter((d) => d.project_id === projectId)
      : []
  }, [projectUsers, responsible_id, projectId])

  const updateMetadata = (optionalFields: IMetaValue[] | undefined) => {
    setValue('optional_fields', optionalFields)
    setTaskFromParent({
      ...taskFromParent,
      optional_fields: optionalFields,
    })
  }

  const onTaskTypeChange = (value?: number | null) => {
    if (!value) {
      updateMetadata(undefined)
    } else {
      updateMetadata(getMetaData(value, taskTypes))
    }
  }

  useEffect(() => {
    if (!defaultTask?.task_type_id) return
    const metaFields = getMetaData(defaultTask?.task_type_id, taskTypes)
    updateMetadata(metaFields)
    // there is no way to avoid this :-(
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTask?.task_type_id, taskTypes])

  const onChangeConnection = async (update: ITaskFormInput) => {
    for (const key of Object.keys(update) as (keyof ITaskFormInput)[]) {
      setValue(key, update[key] as ITaskFormInput)
    }
    setTaskFromParent({ ...taskFromParent, ...update })
  }

  const setDeadlineDatepickerFocus = (arg: { focused: boolean }) => {
    setDeadlineDatePickerFocused(arg.focused)
  }

  const invalidDate = (
    value: moment.Moment,
    lastAllowedDeadline?: moment.Moment,
  ) => {
    if (!lastAllowedDeadline) return false
    return moment(value).isAfter(lastAllowedDeadline, 'days')
  }

  const rule = (value: string) => {
    if (multiEdit) return undefined
    else
      return {
        required: t(value),
      }
  }

  return (
    <Modal
      show
      title={title ?? t('new_task')}
      closeModal={closeModal}
      maxWidth={800}
    >
      <form>
        <div className="flex w-full">
          {multiEdit ? (
            <Controller
              name={'status'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Selector
                  items={actionStatus(t)}
                  selectedItemId={value}
                  onSelect={onChange}
                  label={t('status')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                />
              )}
            />
          ) : (
            <Controller
              defaultValue={defaultTask?.deadline}
              name={'deadline'}
              control={control}
              rules={{
                validate: (value) => {
                  if (!value) return t('required')
                  if (invalidDate(value, lastAllowedDeadline))
                    return deadlineErrorMessage
                  return true
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
                  <label
                    className={
                      'text-sm text-gray-700 font-medium first-capitalize pb-2'
                    }
                  >
                    {t('deadline')}
                    {' * '}
                    <span className="text-red-600 font-normal">
                      {error?.message}
                    </span>
                  </label>
                  <SingleDatePicker
                    focused={deadlineDatePickerFocused}
                    onFocusChange={setDeadlineDatepickerFocus}
                    placeholder={t('deadline')}
                    firstDayOfWeek={1}
                    date={value}
                    onDateChange={onChange}
                    isOutsideRange={() => false}
                    renderDayContents={renderDayContents}
                    id="Starttidspunkt"
                    small={true}
                    showDefaultInputIcon={true}
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    hideKeyboardShortcutsPanel={true}
                  />
                </div>
              )}
            />
          )}
        </div>

        <div className="grid grid-cols-2 gap-y-2">
          <Controller
            defaultValue={defaultTask?.title}
            control={control}
            rules={rule('required')}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                block
                required={!multiEdit}
                label={t('title')}
                value={value}
                onChange={onChange}
                errorMessage={error?.message}
              />
            )}
            name={'title'}
          />
          <Controller
            defaultValue={defaultTask?.duration}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('duration_days')}
                value={value}
                onChange={onChange}
                block={true}
                required={false}
                type={'number'}
                minValue={0}
                errorMessage={error?.message}
                step={'any'}
              />
            )}
            name={'duration'}
          />
        </div>
        <div className="grid grid-cols-2 gap-y-2">
          <Controller
            defaultValue={defaultTask?.discipline_id}
            name="discipline_id"
            control={control}
            rules={rule('select_discipline')}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Selector
                items={responsible_id ? userDisciplines : disciplines}
                onOpenSelectFunction={reFetchDisciplines}
                loading={disciplinesLoading}
                selectedItemId={value}
                onSelect={(id) => {
                  onChange(id)
                  setValue(
                    'contract_id',
                    disciplines?.find((d) => d.id === id)?.contract_id ||
                      undefined,
                  )
                }}
                required={!multiEdit}
                label={t('discipline')}
                dataFields={['shortName', 'name']}
                onCancel={() => {
                  onChange(null)
                }}
                errorMessage={error?.message}
                cancelButton
              />
            )}
          />
          <Controller
            defaultValue={defaultTask?.responsible_id}
            name="responsible_id"
            control={control}
            rules={rule('select_responsible')}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Selector
                errorMessage={error?.message}
                required={!multiEdit}
                items={discipline_id ? disciplineUsers : projectUsers}
                onOpenSelectFunction={
                  discipline_id ? reFetchDisciplineUsers : reFetchProjectUsers
                }
                loading={
                  discipline_id ? disciplineUsersLoading : projectUsersLoading
                }
                selectedItemId={value}
                onSelect={onChange}
                label={t('responsible')}
                dataFields={['firstName', 'lastName']}
                onCancel={() => onChange(null)}
                cancelButton
              />
            )}
          />
        </div>
        <TaskOptionalFields
          control={control}
          visible={multiEdit}
          watch={watch}
          setValue={setValue}
        />
        <Controller
          defaultValue={defaultTask?.description}
          name="description"
          control={control}
          render={({ field: { value, onChange } }) => (
            <Textarea
              label={t('description')}
              value={value}
              isValid={false}
              autoFocus={false}
              onChange={onChange}
              block={true}
            />
          )}
        />
        {!uploadedDocuments ||
          (uploadedDocuments?.length === 0 && (
            <div className="text-sm text-gray-700 font-medium capitalize p-2">
              {t('attachment')}
            </div>
          ))}
        {uploadedDocuments && uploadedDocuments.length > 0 && (
          <UploadFileList
            documents={uploadedDocuments}
            updateDocuments={(docs) => setValue('documents', docs)}
          />
        )}
        {!multiEdit && (
          <>
            <Controller
              name="documents"
              control={control}
              render={({ field: { value, onChange } }) => (
                <UploadFile
                  uploadUrl={getUploadDocURl(projectId, 'Task')}
                  uploadedDocuments={(docs) =>
                    onChange(
                      value && value.length > 0 ? value.concat(docs) : docs,
                    )
                  }
                />
              )}
            />

            <span className="text-sm text-gray-700 font-medium first-capitalize pb-4 px-2">
              {t('connections')}
            </span>
            <TaskRelations
              onChangeInput={onChangeConnection}
              task={taskFromParent}
              inForm
            />
          </>
        )}
        <Controller
          defaultValue={defaultTask?.task_type_id}
          name="task_type_id"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Selector
              items={taskTypes}
              loading={taskTypesLoading}
              onOpenSelectFunction={refetchTaskTypes}
              selectedItemId={value ?? 0}
              onSelect={(id: number) => {
                onTaskTypeChange(id)
                onChange(id)
              }}
              label={t('task_type')}
              dataFields={['taskTypeName']}
              fontSize={'sm'}
              fontWeight={'bold'}
              errorMessage={error?.message}
              cancelButton={true}
              onCancel={() => {
                onTaskTypeChange(undefined)
                onChange(null)
              }}
            />
          )}
        />
        {taskFromParent.optional_fields && (
          <DocumentMetaDataFields
            title={''}
            onFieldsUpdate={updateMetadata}
            values={taskFromParent.optional_fields}
            fields={taskType?.optional_fields ?? []}
            required={false}
          />
        )}
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button
            type={Button.ButtonType.SECONDARY}
            noTextWrap={true}
            onClick={handleSubmit(onSubmit(true))}
            disabled={loading}
            visible={!multiEdit}
          >
            {loading ? <Spinner /> : capitalize(t('add_multiple'))}
          </Button>
          <Button
            disabled={loading}
            type={Button.ButtonType.PRIMARY}
            onClick={handleSubmit(onSubmit(false))}
          >
            {loading ? <Spinner /> : t('save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default CreateTaskForm
