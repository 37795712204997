import { FC } from 'react'
import {
  Controller,
  Control,
  FieldValues,
  UseFormWatch,
  UseFormSetValue,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Selector from 'src/components/selectors/Selector'
import {
  useTags,
  useTeams,
  useMainProcessTeams,
  useMainProcesses,
} from 'src/query/planning/process'

interface TaskOptionalFieldsProps {
  visible: boolean
  control: Control<FieldValues>
  watch: UseFormWatch<FieldValues>
  setValue: UseFormSetValue<FieldValues>
}

const TaskOptionalFields: FC<TaskOptionalFieldsProps> = ({
  visible,
  control,
  watch,
  setValue,
}) => {
  const { t } = useTranslation()

  const mainProcessId = watch('main_process_id')

  const { data: mainProcesses = [] } = useMainProcesses()
  const { data: teams = [] } = useTeams()
  const { data: tags = [] } = useTags()
  const { data: mainProcessTeams } = useMainProcessTeams(mainProcessId ?? 0, {
    enabled: !!mainProcessId,
  })

  if (!visible) return null
  return (
    <>
      <div className="grid grid-cols-2 gap-y-2">
        <Controller
          name={'main_process_id'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <Selector
                items={mainProcesses}
                selectedItemId={value}
                onSelect={(id) => {
                  onChange(id)
                  setValue('team_id', undefined)
                }}
                label={t('main_process')}
                dataFields={['name']}
                fontSize={'sm'}
                cancelButton
                fontWeight={'bold'}
                onCancel={() => onChange(undefined)}
              />
            </div>
          )}
        />
        <Controller
          name={'team_id'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <Selector
                items={mainProcessTeams ?? teams}
                selectedItemId={value}
                onSelect={(id) => {
                  setValue(
                    'main_process_id',
                    teams.find((team) => team.id === id)?.main_process_id,
                  )
                  onChange(id)
                }}
                label={t('team')}
                dataFields={['name']}
                fontSize={'sm'}
                cancelButton
                fontWeight={'bold'}
                onCancel={() => onChange(undefined)}
              />
            </div>
          )}
        />
      </div>
      <div className="grid grid-cols-2 gap-y-2">
        <Controller
          name={'tag_id'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div>
              <Selector
                items={tags}
                selectedItemId={value}
                onSelect={onChange}
                label={t('type')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton
                onCancel={() => onChange(undefined)}
              />
            </div>
          )}
        />
      </div>
    </>
  )
}

export default TaskOptionalFields
